import React from 'react'
import { FlexDiv } from 'design-system/FlexDiv'
import { ForgotPasswordComponent } from 'components/ForgotPasswordComponent'
import { EllicotLayout } from 'components/EllicotLayout'
import image from '../images/apartment-chair-clean-contemporary-279719.png'

const ForgotPasswordPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <FlexDiv
        style={{ height: 'calc(100vh - 90px)' }}
        align="center"
        justify="center"
      >
        <img
          src={image}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -100,
            width: '100%',
            height: '100%',
          }}
        />
        <ForgotPasswordComponent />
      </FlexDiv>
    </EllicotLayout>
  )
}

export default ForgotPasswordPage
