import React from 'react'
import { FlexDiv } from 'design-system/FlexDiv'
import { Button } from 'design-system/Button'
import { Input } from '../design-system/Input'
import { Link } from 'gatsby'

export const ForgotPasswordComponent: React.FC = () => {
  return (
    <div
      style={{ width: 500, background: '#f5f5f5', padding: 40, height: 500 }}
    >
      <FlexDiv vert justify="center" style={{ height: '100%' }} container>
        <div>
          <h1>Forgot password</h1>
          <p>Please enter your email to reset your password</p>
          <FlexDiv style={{ marginBottom: 10 }}>
            <Input altTheme label="Enter your email" style={{ height: 60 }} />
          </FlexDiv>
          <Button alt label="Send" icon="compare.svg" />
          <p style={{ textAlign: 'center' }}>
            <Link to="/login">
              Back to <span style={{ textDecoration: 'underline' }}>Login</span>
            </Link>
          </p>
        </div>
      </FlexDiv>
    </div>
  )
}
